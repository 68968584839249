import { Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { AuthService } from './auth/auth.service';
import {UtilService} from './core/util/util.service'
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LoaderService } from './core/util/loader.service';
import { APP_MODEL_DATA } from './core/const-data';
import { RedirectionService } from './core/redirection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'owner-portal-angular';
  isLogin = false;
  userInfo:any;
  expires = new Date();
  refreshToken = this.authService.getToken('refreshToken');
  expireInterval = this.authService.getLocalStoarge('expireInterval');
  
  constructor(private authService: AuthService,private utilService: UtilService,private router: Router,public translate: TranslateService, private titleService: Title, private loaderService: LoaderService, private redirectionService: RedirectionService){
    // ßconsole.log('route URL = ',this.router.url);
    // Inside your routing component or service
    this.userInfo = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
    let validToken = this.authService.isAuthenticated();
    
    if(this.router.url != 'changePassword'){
      this.utilService.checkLanguageConstraintForApp();
    }
    if(!validToken && this.refreshToken) {
      // this.router.navigate(['signin']);
      // this.router.navigate([this.router.url]); 
      let grantType = 'refresh_token';
      this.callCognitoToken(grantType,this.refreshToken, Number(this.expireInterval));
    } 
    this.router.events.subscribe((event:any) => {
      this.isLogin = this.authService.isAuthenticated();
      // if(!this.isLogin && event?.url =='changePassword') {
      //   this.router.navigate([event?.url]);
      // } 
      // else if(!this.isLogin && event?.url !=='changePassword'){
      //   this.router.navigate(['signin']);
      // } 
      if (event instanceof NavigationStart) {
        this.loaderService.show();
      } else if (event instanceof NavigationCancel || event instanceof NavigationError) {
        this.loaderService.hide();
      } else if(event instanceof NavigationEnd){
        this.loaderService.hide();
        this.redirectionService.handleRedirection(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.titleService.setTitle(event.translations.lang.page_title);
    });
  }



  callCognitoToken(grantType: string , token: string,expireInterval: number){
    this.authService.cognitoToken(grantType,token)?.subscribe((data:any)=>{
      let _now = new Date();
      _now.setTime(_now.getTime() + 1 * (expireInterval * 1000));
      let expires = this.authService.add_year(this.expires,APP_MODEL_DATA.getExpiryTime).toString();
      let id_token = `idToken=${data?.id_token};  expires=${_now.toUTCString()}; path=/;Priority=High;secure;`;
      document.cookie = id_token;
      document.cookie = `refreshToken=${token}; expires=${expires}; path=/;Priority=High;secure;`;
      this.checkSession();
    },
    (error) => {
      console.log(error.error);
    })
  }

  checkSession(){
    this.authService.userSession()?.subscribe((session:any)=>{
      this.authService.setLocalStorage('sessionId',session.session_user_id);
      this.userAccount(session?.session_user_id)
    },
    (error) => {
      console.log(error.error);
      this.loaderService.hide();
    })
  }

  userAccount(sessionId : string){
    this.authService.userAccount(sessionId)?.subscribe((userInfo:any)=>{
      this.authService.setLocalStorage('userInfo',JSON.stringify(userInfo.account));
      let userDetails = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
      if(this.authService.checkUserRole(userDetails)){
        this.router.navigate([ 'owners-center']);
      } else if (userDetails?.userLevel == 'dev'){
        this.router.navigate([ 'engineering']);
      }
      this.loaderService.hide();
    },
    (error) => {
      console.log(error.error);
      this.loaderService.hide();
    })
  }



}
