import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    // Decode the URL to prevent double encoding issues
    url = decodeURIComponent(url);
    // Use the default URL serializer to parse the decoded URL
    return super.parse(url);
  }
}
