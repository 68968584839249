import { Component} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../core/util/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.css']
})
export class AddLocationComponent {
  form: FormGroup;
  form2: FormGroup;
  isStepOneComplete = false;

  constructor(private router: Router, private fb: FormBuilder, private userService: UserService,private translateService: TranslateService,private toastService: ToastService) {
    this.form = this.fb.group({
      serialNo: ['', [Validators.required, Validators.maxLength(15),Validators.minLength(15)]]
    });
    this.form2 = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(18)]]
    });
  }

  get serialNo() {
    return this.form.get('serialNo');
  }

  get name() {
    return this.form2.get('name');
  }
  
  onInput(event: any) {
    const regex = /[^a-zA-Z0-9\-]/g;
    const inputValue = event.target.value.replace(regex, '');
    // const inputValue = event.target.value.replace(/-/g, ''); // Remove existing dashes
    const maskedValue = inputValue.replace(/(\w{3})(?=\w)/g, '$1-'); // Insert dash after every 3 characters
    this.form.controls['serialNo'].patchValue(maskedValue, { emitEvent: false }); // Patch the value without emitting an event
  }

  submitSerialNo(){
    if(this.form.valid){
      this.isStepOneComplete =true;
    }
  }

  reset(){
    this.isStepOneComplete =false;
    this.form.reset();
    this.form2.reset();
  }

  submitLocation(){
    if(this.form2.valid){
      const regex = /-/g; // Regular expression to match hyphens
      let outputString = this.form.controls['serialNo'].value.replace(regex, ''); // Remove hyphens
     this.userService.addLocation(this.form2.controls['name'].value,outputString)?.subscribe(
      (response:any)=>{
        console.log('response',response)
        if(response?.messages[0]?.text == "device_registered"){
          this.toastService.showSuccess(this.translateService.instant('lang.device_registered'),this.translateService.instant('lang.modal_saving_success'));
          this.router.navigate([ 'owners-center']);
        }
        this.reset();
      },
      (error:any)=>{
        console.log('error',error)
        this.reset();
      }
     )
    }
  }

}
