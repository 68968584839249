import { Injectable, inject  } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';

export const authGuard: CanActivateFn = (route, state) => {
  return true;
};

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  userInfo: any | undefined;
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    let validToken = this.authService.isAuthenticated();
    this.userInfo = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
    // const expextedRole = next.data.expectedRole;
    // const expectedRole = this.userInfo?.userLevel == 'user'
    if(validToken){
      if(state.url != '/signin' && (this.authService.checkUserRole(this.userInfo))){
        return true;
      } else {
        return false;
      }
    } else {
      this.router.navigate(['signin']);
      return false;
    }
    // if ((validToken && state.url != '/signin') && (this.authService.checkUserRole(this.userInfo))) {
    //   return true;
    // } else if ((validToken && state.url == '/signin') && (this.authService.checkUserRole(this.userInfo))) {
    //   //this.router.navigate(['signin']);
    //   return false;
    // } 
    // else {
    //   this.router.navigate(['signin']);
    //   return false;
    // }
  }
  static engineerUser: CanActivateFn = () => {
    const authService = inject(AuthService);
    const userInfo = JSON.parse(authService.getLocalStoarge('userInfo') || '{}');
    // let validToken = authService.isAuthenticated();
    return authService.isAuthenticated() && userInfo?.userLevel == 'dev' ? true : false;
  };
  static roleBaseNavigation: CanActivateFn = () => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const userInfo = JSON.parse(authService.getLocalStoarge('userInfo') || '{}');
    // let validToken = authService.isAuthenticated();
    const role = userInfo?.userLevel;
    switch (role) {
      case 'dev':
        router.navigate(['/engineering']);
        break;
      case 'user':
        router.navigate(['/owners-center']);
        break;
      case 'poolpro_user':
        router.navigate(['/owners-center']);
        break;
      default:
        router.navigate(['/login']); // Or '/welcome' for guests or unauthenticated users
        break;
    }

    return false;  // Return false to cancel the current navigation
    // return authService.isAuthenticated() && userInfo?.userLevel == 'dev' ? true : false;
  };
}
