import { Component, OnInit } from '@angular/core';
import { FormControl, AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoaderService } from '../../core/util/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth.service';
import { UtilService } from '../../core/util/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../core/util/toast.service';
import { environment } from '../../../environments/environment';
import { ModalService } from '../../core/util/modal.service';
import { ModalOptions } from 'ngx-bootstrap/modal';

interface IUser {
  reset_password_token: string,
  newPassword: string,
  confirmPassword: string
}

// password_change_success
@Component({
  selector: 'app-forgot-user-password',
  templateUrl: './forgot-user-password.component.html',
  styleUrls: ['./forgot-user-password.component.css']
})
export class ForgotUserPasswordComponent implements OnInit  {
  forgotPasswordForm!: FormGroup;
  user: IUser;
  regularExpression = new RegExp(String.raw`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`])[a-zA-Z0-9!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`]{${environment.passwordMinLength},${environment.passwordMaxLength}}$`);
  passwordMatch = false;
  reset_password_token :any = '';


  constructor(private route: ActivatedRoute,private modalService: ModalService,private translateService: TranslateService, private authService: AuthService, private utilService: UtilService, public router: Router, private formBuilder: FormBuilder, private toastService: ToastService, private loaderService: LoaderService) {
    // this.utilService.checkLanguageConstraintForSignIn();
    this.user = {} as IUser;
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.reset_password_token = params.get('reset_password_token');
    });
   // console.log(this.reset_password_token);
    this.forgotPasswordForm= this.formBuilder.group({  
      newPassword:['',Validators.required],
      confirmPassword:[null,Validators.required],     
    });
    this.forgotPasswordForm.controls["newPassword"].setValidators([Validators.required,Validators.pattern(this.regularExpression)]);
    this.forgotPasswordForm.controls["confirmPassword"].setValidators(Validators.required);
  }

     // function to validate form fields for conditional display of validation messages
  isFieldValid(field: string) {
    if(field == 'confirmPassword' && this.forgotPasswordForm?.get('confirmPassword')?.touched){
      return this.passwordMatch = this.forgotPasswordForm?.get('confirmPassword')?.value !== this.forgotPasswordForm?.get('newPassword')?.value ? true : false;
    }
   
    return this.forgotPasswordForm?.get(field)?.invalid && this.forgotPasswordForm.get(field)?.touched;
  }

  get forgotPasswordFormControl(): { [key: string]: AbstractControl } {
    return this.forgotPasswordForm.controls;
  }

  public validate(): void {
    if (this.forgotPasswordForm.invalid) {
      for (const control of Object.keys(this.forgotPasswordForm.controls)) {
        this.forgotPasswordForm.controls[control].markAsTouched();
      }
      return;
    } else {
      this.user = this.forgotPasswordForm.value;
      this.user.reset_password_token = this.reset_password_token;
      console.log(this.user);
      this.onSubmit();
    }
  }

  onSubmit(): void {
    // {"reset_password_token":"CVszRcyNzKkAVVPoG60_","newPassword":"System@12345","confirmPassword":"System@12345"}
    // this.openModal();
    this.authService.forgotPassword(this.user)?.subscribe(
      (response: any) => {
        if(response?.messages[0]?.text == 'reset_password_msg'){
          this.openModal();
        }
      },
      (error) => {
        console.log(error.error);
      }
    );
  }
    // * Open model pop up
  openModal(): void { 
      const title = 'Success';
      const data = this.translateService.instant('lang.password_change_success')
      const content = data;
      const isHTML = false;
      const buttonsConfig = [
        {  class:"btn btn-primary",label: 'Return to Login', action: () => this.navigateSignIn() }
      ];
      const onClose = () => {};
      const onShow = () => {};
      const modalConfig: ModalOptions = {
        class: 'modal-dialog-centered', // ? Center modal vertically
        backdrop: 'static', // ? Prevent modal from closing on backdrop click
        keyboard: false, // ? Prevent modal from closing on escape key press
      };
      this.loaderService.hide();
      this.modalService.openModal(title, content,modalConfig, isHTML, buttonsConfig, onClose, onShow);
  }

    navigateSignIn():void{
      this.modalService.closeModal();
      this.router.navigate(['/signin'])
    }

}
