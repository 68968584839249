import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS  } from '@auth0/angular-jwt';

import { AuthGuardService } from './auth/auth.guard';
import { HeaderComponent } from './core/layout/header/header.component';
import { FooterComponent } from './core/layout/footer/footer.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { langTranslateHttpLoader } from './lang-translate.loader';
import { HttpClientModule } from "@angular/common/http";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ModalComponent } from './core/util/modal/modal.component';
import { PanelModule } from 'primeng/panel';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


import { ModalModule } from 'ngx-bootstrap/modal';
import { LoaderComponent } from './core/util/loader/loader.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotUserPasswordComponent } from './auth/forgot-user-password/forgot-user-password.component';
import { DeleteAccountComponent } from './auth/delete-account/delete-account.component';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { RegisterDeviceComponent } from './device/register-device/register-device.component';
import { RedirectComponent } from './core/redirect/redirect.component';
import { ViewDeviceComponent } from './device/view-device/view-device.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PrivacypolicyComponent } from './auth/privacypolicy/privacypolicy.component';
import { CustomUrlSerializer } from './core/custom-url-serializer'; // Import the custom URL serializer
import { UrlSerializer } from '@angular/router';
import { FluidraProSupportComponent } from './deeplink/fluidra-pro-support/fluidra-pro-support.component';


@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    LoaderComponent,
    ForgotUserPasswordComponent,
    DeleteAccountComponent,
    DeviceListComponent,
    RegisterDeviceComponent,
    RedirectComponent,
    ViewDeviceComponent,
    PageNotFoundComponent,
    PrivacypolicyComponent,
    FluidraProSupportComponent
  ],
  imports: [
    BrowserModule,
    PanelModule,
    DividerModule,
    TooltipModule,
    BrowserAnimationsModule,
    DashboardModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 25000,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: langTranslateHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: UrlSerializer, useClass: CustomUrlSerializer }, // Provide the custom URL serializer
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
