import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {TranslateService } from '@ngx-translate/core';
import {AuthService} from './../../auth/auth.service';

import { APP_MODEL_DATA } from '../const-data';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private router: Router, public activatedRoute: ActivatedRoute, public translate: TranslateService, private authService: AuthService) { }
  
  checkLanguageConstraintForApp() {
    this.translate.addLangs(APP_MODEL_DATA.SUPPORTED_LANG_TRANSLATIONS);
    let currentLanguage = this.authService.getLocalStoarge("language");
    let params = this.getUrlParams();
    if (params["lang"] === undefined) {
      let lang = currentLanguage ? currentLanguage : 'en';
      this.authService.setLocalStorage("language", lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang)
    }
  }

  checkLanguageConstraintForSignIn() {
   let currentLanguage = this.authService.getLocalStoarge("language")?.toLocaleLowerCase();
    let params = this.getUrlParams();
    if (params["lang"] === undefined || this.getLangFromParam(params["lang"]) !== currentLanguage || this.getLangFromParam(params["lang"]) == currentLanguage) {
      if(currentLanguage !== params["lang"]){
        let lang = (params["lang"] === undefined)? currentLanguage || 'en' : this.getLangFromParam(params["lang"]) || currentLanguage || 'en';
        this.authService.setLocalStorage("language", lang);
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
        this.router.navigate([this.router.url],{queryParams:{"lang": lang}});
      }
    }
  }

  getLangFromParam(val: string) {
    if (APP_MODEL_DATA.SUPPORTED_LANG_TRANSLATIONS.includes(val.toLocaleLowerCase())) {
        return val.toLocaleLowerCase();
    } else {
        return 'en';
    }
  }

  getUrlParams() {
    let urlParams: any;
    this.activatedRoute.queryParams
      .subscribe(params => {
        urlParams = params;
      }
    );
    return urlParams;
  }

}
